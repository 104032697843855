<template>
    <section>
      
      <div class="columns is-marginless">
        <div class="column is-12">    
            <div class="columns is-gapless is-marginless is-centered is-mobile">
              <div class="column is-narrow is-mobile">
                <div v-if="companyData !== null" class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >
                  {{ companyData.companies_id }} - {{ companyData.companies_name }} -> Users
                </div>
              </div>
            </div>
        </div>
      </div>
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Roles</h1>
        <b-table
            :data="rolesData"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableRolesIsLoading"
            :mobile-cards="true">

            <b-table-column field="company_role_company_id" label="company id" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered>
                {{ props.row.company_role_company_id }}
            </b-table-column>
            <b-table-column field="company_role_role_id" label="role id" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered>
                {{ props.row.company_role_role_id }}
            </b-table-column>
            <b-table-column field="roles_name" label="role name" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered>
                {{ props.row.roles_name }}
            </b-table-column>
                
            
            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>
        
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Company_Users</h1>
        <b-switch v-model="showCompanyUserData"> {{ showCompanyUserData ? 'Hide' : 'Show' }} </b-switch>
        <b-field v-show="showCompanyUserData" label="filter">
          <b-input v-model="companyUserFilterStr" maxlength="20000" placeholder="filter on email or userId or big" 
                  type="textarea" @input="filterUserData">
          </b-input>
        </b-field>
         <div v-for="(filterIssues, key) in userDataFitlerIssues" v-bind:key="`filterIssues_${key}_index`" style='color: red;margin:5px;'>
            {{ filterIssues }}
          </div>
        <b-table
            v-show="showCompanyUserData"
            :data="userDataFiltered"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableIsLoading"
            :mobile-cards="true">

            <b-table-column field="company_user_id" label="company user id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.company_user_id }}
            </b-table-column>
            
            <b-table-column field="user_id" label="user id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                <a :href='`${WEB_ROOT}/company/${props.row.company_id}/user/${props.row.user_id}`' target='_blank'>{{ props.row.user_id }}</a>
            </b-table-column>

            <b-table-column field="users_name" label="user Name" width="200" :td-attrs="columnTdAttrs" v-slot="props" sortable searchable>
                <a :href='`${WEB_ROOT}/company/${props.row.company_id}/user/${props.row.user_id}`' target='_blank'>{{ props.row.users_name }}</a>
            </b-table-column>
            <b-table-column field="profileName" label="profile Name" width="200" :td-attrs="columnTdAttrs" v-slot="props" sortable searchable>
                <a :href='`${WEB_ROOT}/company/${props.row.company_id}/user/${props.row.user_id}`' target='_blank'>{{ props.row.profileName }}</a>
            </b-table-column>
            <b-table-column field="users_email" label="email" width="200" :td-attrs="columnTdAttrs" v-slot="props" sortable searchable>
                <a :href='`${WEB_ROOT}/company/${props.row.company_id}/user/${props.row.user_id}`' target='_blank'>{{ props.row.users_email }}</a>
            </b-table-column>
            
            
            
            <b-table-column field="profiles_mobile" label="mobile" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.profiles_mobile }}
            </b-table-column>

            <b-table-column field="rolesSrt" label="roles" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.rolesSrt }}
            </b-table-column>
            
            <!-- <b-table-column field="quotum" width="40" label="hours quotem" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.quotum }}
            </b-table-column> -->
            <!-- <b-table-column field="min_hours" width="40" label="min" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.min_hours }}
            </b-table-column> -->
            <b-table-column field="bignumbers" label="bignumber(s)" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.bignumbers }}
            </b-table-column>
            <!-- <b-table-column field="max_hours" width="40" label="max" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.max_hours }}
            </b-table-column> -->
            <!-- <b-table-column field="user_blocks" width="40" label="user blocks" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.user_blocks }}
            </b-table-column>
            <b-table-column field="blockedDayCount" width="40" label="blocked days" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.blockedDayCount }}
            </b-table-column> -->
                        
            <b-table-column field="departmentIdList" label="department ids" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.departmentIdList }}
            </b-table-column>
            
            
            <b-table-column field="users_is_active" label="users is active" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.users_is_active }}
            </b-table-column>
            

            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
        <b-button :type="'is-success'" @click="copyToClipboard('profiles_mobile')">copy mobile</b-button>
        <b-button :type="'is-success'" @click="copyToClipboard('users_email')">copy email</b-button>
        <b-button :type="'is-success'" @click="copyToClipboard('bignumbers')">copy big</b-button>
        <b-button :type="'is-success'" @click="copyToClipboard('user_id')">copy userId</b-button>
        <b-button :type="'is-success'" @click="copyToClipboard('company_user_id')">copy companyUserId</b-button>
      </div>
      
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Application_Users</h1>
        <b-switch v-model="showApplicationUsers"> {{ showApplicationUsers ? 'Hide' : 'Show' }} </b-switch>
        <b-table
            v-show="showApplicationUsers"
            :data="applicationUserData"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableApplicationUsersIsLoading"
            :mobile-cards="true">

            <b-table-column field="applications_company_id" label="company id" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.applications_company_id }}
            </b-table-column>
            <b-table-column field="application_user_user_id" label="user id" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.application_user_user_id }}
            </b-table-column>
            <b-table-column field="users_name" label="name" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.users_name }}
            </b-table-column>
            <b-table-column field="users_email" label="email" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.users_email }}
            </b-table-column>
            <b-table-column field="rolesStr" label="roles" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.rolesStr }}
            </b-table-column>
            <b-table-column field="users_is_active" label="users is active" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.users_is_active }}
            </b-table-column>
            <b-table-column field="users_automatic_guest_account" label="guest account" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.users_automatic_guest_account }}
            </b-table-column>
            <b-table-column field="users_delete_requested_at" label="delete requested at" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.users_delete_requested_at ? DDMMYYYY_HHii(new Date(props.row.users_delete_requested_at )) : '' }}
            </b-table-column>
            <b-table-column field="users_deleted_email" label="users deleted email" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.users_deleted_email }}
            </b-table-column>
            
            <b-table-column field="application_user_applied_at" label="applied at" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.application_user_applied_at ? DDMMYYYY_HHii(new Date(props.row.application_user_applied_at )) : '' }}
            </b-table-column>
            <b-table-column field="application_user_accepted_at" label="accepted at" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.application_user_accepted_at ? DDMMYYYY_HHii(new Date(props.row.application_user_accepted_at )) : '' }}
            </b-table-column>
            <b-table-column field="application_user_is_rejected" label="is rejected" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.application_user_is_rejected ? DDMMYYYY_HHii(new Date(props.row.application_user_is_rejected )) : '' }}
            </b-table-column>
            <b-table-column field="application_user_rejected_reason" label="rejected reason" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.application_user_rejected_reason }}
            </b-table-column>
            <b-table-column field="applications_is_active" label="is active" width="40" :td-attrs="columnTdAttrs" string v-slot="props" sortable centered searchable>
                {{ props.row.applications_is_active }}
            </b-table-column>
            
            

            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>
      
      
      
      <b-notification :closable="false">
        <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
      </b-notification>
    </section>
</template>

<script>
/* eslint-disable */
import axios_api from '@/plugins/axios_api';
// import { waitTime }         from '@/helpers/functions';
// import { mapState } from 'vuex';
import { DDMMYYYY_HHii } from '@/helpers/dates.js';


import { WEB_ROOT, API_ROOT } from '@/config/app.js';

    export default {
      data() {
        return {
          companyId           : null,
          companyData         : null,
          
          userData            : [],
          companyUserFilterStr: ``,
          userDataFiltered    : [],
          userDataFitlerIssues: [],
          tableIsLoading      : false,
          showCompanyUserData : true,
          
          rolesData             : [],
          tableRolesIsLoading   : false,
          
          showApplicationUsers: false,
          applicationUserData : [],
          tableApplicationUsersIsLoading: false,
          
          WEB_ROOT: WEB_ROOT,
          API_ROOT: API_ROOT,
          
          pageLoading         : false,
          pageLoadingTimeout  : null,
        }
      },
      async mounted() {
        this.companyId = this.$route.params.companyId;
        this.ShowPageLoading(15000)
        this.getUserData()
      },
      methods: {
        DDMMYYYY_HHii,
        
        async getUserData() {
          let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}/users`, {});
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company user data', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          // console.info(`response:`, response)
          this.companyData = response.data.payload.companyData
          this.userData = response.data.payload.userData
          this.rolesData = response.data.payload.companyRoles
          this.applicationUserData = response.data.payload.applicationUsersData
          this.filterUserData()
          this.HidePageLoading()
        },
        filterUserData(){
          if (this.companyUserFilterStr.trim() === ``) {
            this.userDataFiltered = this.userData
            return
          }
          
          
          let filterStr = this.companyUserFilterStr.toString().toLocaleLowerCase()
          // console.info(`filterStr:`, filterStr)
          let lines = filterStr.split(`\n`)
          // console.info(`lines:`, lines)
          let issues = []
          let filterList = []
          
          for(let line of lines) {
            line = line.trim()
            if (line === ``) continue
            
            console.info(`checking line: -${line}-`)
            
            if (line.indexOf(`@`) > -1) {
              const find = this.userData.find(u => u.users_email.toLocaleLowerCase() === line)
              console.info(`find line --${line}--:`, find)
              if (!find) {
                issues.push(`unable to find email: ${line}`)
                continue
              } else if (filterList.find(u => u.user_id === find.user_id)) {
                issues.push(`duplicate user email: ${line}`)
                continue
              }
              filterList.push(find)
              continue
            }
            
            let number = parseInt(line)
            if (isNaN(number) || number <= 0) {
              issues.push(`invalid number ${line}`)
              continue
            }
            
            const findUserId  = this.userData.find(u => u.user_id === number)
            const findBig     = this.userData.find(u => new Set(u.bignumberList).has(number))
            
            if (!findUserId && !findBig) {
              issues.push(`unable to find userId/bignumber: ${line}`)
              continue
            } 
            
            if (findUserId) {
              if (filterList.find(u => u === findUserId)) {
                issues.push(`duplicate number: ${line}`)
                continue
              }
              filterList.push(findUserId)
              continue
            }
            
            if (findBig) {
              if (filterList.find(u => u === findBig)) {
                issues.push(`duplicate bignumber: ${line}`)
                continue
              }
              filterList.push(findBig)
              continue
            }
            
          }
          // console.info(`issues:`, issues)
          this.userDataFitlerIssues = issues
          this.userDataFiltered = filterList
        },
        
        copyToClipboard(field) {
          let list = []
          for(let item of this.userDataFiltered) {
            list.push(item[field])
          }
          navigator.clipboard.writeText(list.join(`\n`));
          this.$buefy.toast.open(`${field} copied to clipboard`);
        },
        
        /* eslint-disable-next-line */        
        dateThAttrs(column) {
          return null
        },
                
        /* eslint-disable-next-line */
        columnTdAttrs(row, column) {
          return null
        },
        
        async ShowPageLoading(maxDuration = 30000){
          this.pageLoading = true;
          
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
          
          this.pageLoadingTimeout = setTimeout(() => {
            this.HidePageLoading();
          }, maxDuration);
        },
        
        async HidePageLoading() {
          this.pageLoading = false;
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
        },
      }
    }
</script>